import { z as zod } from "zod";

// This is a zod schema for the analysis get field layers response.
// This can be replaced with an autogenerated schema from the api
// package at some point if we want.

const layerSchema = zod.object({
  fieldId: zod.string().uuid(),
  businessCycleId: zod.string().uuid().optional(),
  productionCycleId: zod.string().uuid().optional(),
  commonCycleId: zod.string().uuid().optional(),
  cropYear: zod.number().optional(),
  cropId: zod.string().uuid().optional(),
  cropSeasonId: zod.string().uuid().optional(),
  layerName: zod.string(),
  rateLayerName: zod.string().optional(),
  dates: zod.array(zod.string()).optional(),
  inputId: zod.string().uuid().optional(),
  commodityId: zod.string().uuid().optional(),
  applicationId: zod.string().uuid().optional(),
  productApplicationId: zod.string().uuid().optional(),
  flattenedRasterUrl: zod.string(),
  flattenedRateRasterUrl: zod.string().optional(),
});

export const getAnalysisFieldLayersResponse = zod.object({
  layers: zod.array(layerSchema),
});

// Note: this schema contains all values needed to query the endpoint. This
// is a get endpoint, though, and these values aren't used as a single POST
// body or something like that. 👀 just so you know.
export const flattenedRasterRequest = zod.object({
  operationId: zod.string().uuid(),
  fieldId: zod.string().uuid(),
  layerDefName: zod.string(),
  cropCycle: zod.number().optional(),
  cropId: zod.string().uuid().optional(),
  forcePredictor: zod.string().optional(),
});

export const flattenedRasterResponse = zod.object({
  raster: zod.any(),
  pixelCount: zod.number(),
  area: zod.number(),
  tiffMapping: zod.record(zod.string()),
  etag: zod.string().optional(),
  rasterUnit: zod.string().optional(),
});
