/**
 * - TODO: This is on the verge of being 'too long to follow' and may need
 *         to be split up.
 */

import type { ComponentType } from "react";
import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAtom } from "jotai";

import {
  useInsightsUrl,
  useFeatureFlags,
  withIntlProvider,
  useUserLocale,
  useUser,
  disableNavState,
} from "@granular/fabric3-core";
import { FabricApp, type PackageDataApp } from "@granular/fabric3-definitions";
import { FlexibleNavItem, GKIconName, GKLayoutShell } from "@granular/gds";

import Hello from "./Hello";
import ComingSoon from "./ComingSoon";
import NotFound from "./components/404";
import Loading from "./components/Loading";
import { Chat } from "./components/Chat";
import { ImpersonationContext } from "./components/ImpersonationContext";
import { AlertCleaner } from "./components/AlertCleaner";
import Dot, { DotColor } from "./components/Dot";
import translations from "./i18n";
import {
  APP_DISABLED_MESSAGE,
  APP_ICON_IF_UNSPECIFIED,
  APPS_LOWER_SIMPLE,
  CONTAINER_WINDOW_NAME,
  CORE_NAV_GROUPINGS_AND_ORDER,
} from "./constants";

/** TODO: What do these do? */
import {
  PathsAndCutoffDates,
  useLandingPageByDateOnFreshLogin,
} from "./hooks/landingPageByDate";

/**
 * So, what's going on here? The idea is that when the user wants to navigate
 * back to Classic Insights, they will trigger this function which will first
 * open a new blank tab with the name "classic-insights-browser-tab". If that
 * tab is indeed blank then it will take them to Classic. But the next time
 * they click on this same btn, and here's the magic, it will not open a new
 * tab, but switch to the previously opened one, if it's still alive of course,
 * otherwise it'll just open a new tab again.
 */
const goToClassicInsights = (url: URL) => {
  const isFirefoxBrowser = navigator.userAgent.includes("Firefox");
  const classicInsightsTabName = "classic-insights-browser-tab";

  const classicInsightsTabRef = window.open("", classicInsightsTabName);

  if (isFirefoxBrowser) {
    classicInsightsTabRef?.focus();
  }

  if (classicInsightsTabRef?.location.href === "about:blank") {
    window.open(url, classicInsightsTabName);
  }
};

/**
 * A component to contain the useLandingPageByDateOnFreshLogin() hook and
 * allow is to call it conditionally, since it was causing a flickering bug
 * on screen due to the useNavigate() hook that it calls internally.
 * Actual cause of the flicker is yet TBD, this is a patch and should be
 * good for the time being.
 */
const RedirectToLandingPageHookContainer: React.FC<{
  navApps: FabricApp[];
}> = ({ navApps }) => {
  /**
   * An array of objects detailing the path to navigate to on a fresh login
   * and ending date of the time of the year that path should be navigated to.
   */
  const pathsAndCutoffDates: PathsAndCutoffDates = [
    // { path: "harvest-analysis", date: "7/31" },
    // { path: "field-plans", date: "5/31" },
    // { path: "proposals", date: "12/31" }, // TODO: restore this once proposals in prod-ready
    // { path: "profile", date: "12/31" }, // TODO: remove this altogether once proposals is prod-ready
    { path: "data/dashboard", date: "1/30" }, // TODO: Dashboard will be a landing page for the January launch in 2025 but remove this altogether once proposals is prod-ready
  ];

  useLandingPageByDateOnFreshLogin(navApps, pathsAndCutoffDates);

  return false;
};

/**
 * When developing _locally_ (and only locally!) we show all applications
 * to the developer. NO EXCEPTION: We are not going to do this in any
 * higher environment.
 *
 * Show a colored dot to the developer that indicates the status/value of
 * the application Feature Flag.
 *
 * NOTE: Do not get carried away with trying to I18n this. These dots are
 * only visible to developers!
 */
const getDot = (visible: boolean, routable: boolean) =>
  !visible && !routable
    ? ["red", "Hidden: 𝙉𝙤𝙩 Visible, 𝙉𝙤𝙩 Routable"]
    : visible && !routable
      ? ["gray", "Grayed out: Visible, 𝙉𝙤𝙩 Routable"]
      : !visible && routable
        ? ["orange", "Hidden: 𝙉𝙤𝙩 Visible, Routable"]
        : [undefined, undefined, undefined];

/** ═════════════════════════ Container Component ═════════════════════════ */

export const Container: React.FunctionComponent = () => {
  /**
   * Give a name to the current tab so it we can switch between tabs, instead
   * of creating a new one everytime we click on the `Go to Insights/NRE` button
   * on either app. This name is unique and should not ever be reassigned.
   * Classic insights is aware of this name an calls its tab with it.
   * If you change it, it all falls apart, so please don't.
   */
  window.name = CONTAINER_WINDOW_NAME;

  const flags = useFeatureFlags();
  const isLocalhost = import.meta.url.includes("localhost");

  const locale = useUserLocale();
  const intl = useIntl();
  const insightsUrl = useInsightsUrl();
  const user = useUser();

  /* ——————————————————————— Build App List ——————————————————————————— */

  /**
   * Now we use Vite's awesome glob import feature to add apps to the sidebar.
   * For each app found in `apps/` we use the `fabric3:sidebar` key in its
   * `package.json` to get the full name, short name (which is used when the
   * sidebar is collapsed), icon, and the app itself. We do this when the
   * Container mounts.
   *
   * If this alarms you, I encourage you to read this to get an idea of how
   * Vite transforms these dynamic imports 🪄
   *
   * https://vitejs.dev/guide/features.html#glob-import
   *
   * Once we've generated our list, we're ready to draw the sidebar and set up
   * all routes with React Router.
   *
   * NOTE: 🚨 DO NOT ADD ANYTHING HERE BY HAND 🚨
   */

  const appMetadata: Array<[string, PackageDataApp]> =
    /**
     * Convert the object returned by `import.meta.glob` into a list of
     * lists. Easier to manipulate. Please keep reading.
     */
    Object.entries(
      /**
       * This returns an object that has the relative path of the app as the
       * key and the app's package.json data as the value.
       */
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      import.meta.glob("../../apps/**/package.json", {
        import: "default",
        eager: true,
      }) as Record<string, PackageDataApp>,
    )
      /**
       * Step I: Filter out templates. If you're one of the few Fabric3
       * maintainers, just uncomment this if you need to work on them.
       */
      .filter(
        ([_, packageData]) =>
          !packageData.name?.startsWith("@granular/fabric3-template"),
      )
      /**
       * Step II: Filter out unpublished apps. This is just the first pass.
       * We're looking for a key called `publish` under the `fabric3` key in
       * package.json. An 'unpublished' app will not be compiled into the
       * Container via ESBuild. Apps that are 'published' may be in/visible or
       * un/routable. Please keep reading.
       *
       * NOTE: When you develop locally, all apps are always published,
       * visible, and routable!
       */
      .filter(([_, packageData]) =>
        !isLocalhost && !packageData.fabric3.publish ? false : true,
      )
      /**
       * Step III: We now replace the keys. We extract the app's namespace from
       * the relative path provided to us by the import meta glob. So:
       *
       *    "../../apps/data/package.json"  👉  "data"
       *
       * Note that we're only looking for lower-kebab-cased apps in the regex.
       * The map function will return a list of the app's namespace and it's
       * package info. All done! ✨
       */
      .map(([relativePath, packageData]) => [
        /..\/..\/apps\/([\w.-]+)\/package.json/.exec(relativePath)![1]!,
        packageData,
      ]);

  /**
   * At this point, we take the list-of-lists above and transform it to two
   * additional targets. We'll start with the first and use it to make the
   * second. We could've done this above but beh.
   *
   * The first one is a meta list of build/runtime information on our apps.
   * We'll use this soon to tell the Container how to map a namespace to its
   * component. Note the typing here: we're turning Package Data to a 'real'
   * FabricApp 😎
   */
  const appRuntimeInfo: FabricApp[] = appMetadata
    /**
     * Use a `flatMap` since a `fabric3:sidebar` key is an array that a single
     * app/namespace can use to specify multiple routes that are displayed
     * in the Core Nav.
     */
    .flatMap(([namespace, packageData]) => {
      return packageData.fabric3?.sidebar?.map((sidebarItem) => ({
        ...sidebarItem,
        namespace,
        label: intl.formatMessage({
          id: sidebarItem.label,
          defaultMessage: sidebarItem.label,
        }),
        /**
         * Add some ✨ Additional properties ✨
         *
         * Let's start with the _actual_ icon.
         */

        /**
         * Icons: we're being defensive in case people mistype an icon name.
         */
        icon: sidebarItem.icon
          ? GKIconName[sidebarItem.icon as keyof typeof GKIconName] ||
            APP_ICON_IF_UNSPECIFIED
          : APP_ICON_IF_UNSPECIFIED,

        /**
         * Small app badge that we _may_ display next to a label. Note that we
         * use `pnpm monorepo:check-package-json` to validate the shape and
         * values here.
         */
        ...(sidebarItem.badge && {
          badge: {
            label: intl.formatMessage({ id: sidebarItem.badge.label }),
            color: sidebarItem.badge.color,
          },
        }),

        /**
         * This has a bit of nuance in terms of:
         *
         * a) How GDS expects the route,
         * b) How React Router expects this route, and
         * c) How individual apps can split routing.
         */
        to: sidebarItem.route
          ? `${namespace}/${sidebarItem.route}`
          : `${namespace}`,

        /**
         * The all-important Namespace 👉 Component mapping!
         */
        component: lazy(
          () =>
            import(`../../apps/${namespace}/src/App.tsx`) as Promise<{
              default: ComponentType<unknown>;
            }>,
        ),

        /**
         * Visibility and Routability are two different things and are
         * managed entirely via feature-flag state.
         *
         * https://fabric3.granular.ag/docs/the-container/corenav#visibility-and-routability
         *
         * TODO: Remove boolean condition
         */
        visible:
          flags[`app-${namespace}`] === true || // REMOVE THIS SOON
          flags[`app-${namespace}`] === 1 ||
          flags[`app-${namespace}`] === 2,
        routable:
          flags[`app-${namespace}`] === true || // REMOVE THIS SOON
          flags[`app-${namespace}`] === 2 ||
          flags[`app-${namespace}`] === 3,
      }));
    });

  /**
   * Second and Final transformation. We use the object above to tell GDS how
   * to draw the sidebar/Core Nav. In order, we
   *
   * 1) Tell sort the apps by their groupings. If a grouping is `null` (i.e. an
   *    app is in the upper third of the Core Nav), we assign a dummy grouping
   *    called `NO_GROUPING`
   *
   * 2) Sort by weight within groupings
   *
   * 3) Prune any empty groupings.
   */
  type WeightedNavItem = FlexibleNavItem & {
    weight: number;
  };

  const coreNavItems = Object.fromEntries([
    ...CORE_NAV_GROUPINGS_AND_ORDER.map((_) => [
      intl.formatMessage({ id: _ }).toUpperCase() ?? _,
      [],
    ]),
    ["NO_GROUPING", []],
  ]) as Record<string, WeightedNavItem[]>;

  for (const app of appRuntimeInfo) {
    const [dotColor, dotText] = getDot(app.visible, app.routable);

    // TODO: Yes, there could be a lookup map but @nikhilanand will rewrite the
    //       Sidebar soon. This will have to suffice for now.
    const translatedGrouping = app.grouping
      ? intl.formatMessage({ id: app.grouping }).toUpperCase()
      : "NO_GROUPING";

    coreNavItems[translatedGrouping]?.push({
      to: app.to,
      label: app.label,
      shortLabel: app.shortLabel,
      icon: app.icon as GKIconName | undefined,
      weight: app.weight,
      tooltip:
        app.visible && !app.routable
          ? app.disabledTooltip ?? APP_DISABLED_MESSAGE
          : undefined,

      hidden: isLocalhost ? false : !app.visible,
      disabled: isLocalhost ? false : !app.routable,
      prepend: isLocalhost ? (
        <Dot
          color={dotColor as DotColor}
          toolTipText={dotText}
          key={app.label}
        />
      ) : undefined,
      badge: app.badge?.label,
      badgeColor: app.badge?.color,
    });
  }

  // Sort groupings by weight. Remove any empty groupings.
  for (const group in coreNavItems) {
    const g = group;

    if (coreNavItems[g] !== undefined) {
      coreNavItems[g] = coreNavItems[g].sort((a, b) =>
        a.weight > b.weight ? 1 : a.weight < b.weight ? -1 : 0,
      );

      if (coreNavItems[g].length === 0) {
        delete coreNavItems[g];
      }
    }
  }

  const topNavItems = coreNavItems.NO_GROUPING;
  const groupedNavItems = Object.fromEntries(
    Object.entries(coreNavItems).filter(([key]) => key !== "NO_GROUPING"),
  );

  /**
   * Most of the sidebar is generated from the apps themselves, because a menu
   * item must have an app/component to point to. But in some cases, you may
   * want to add some links that are not part of the apps.
   *
   * Notice that most of these NavItems that are created outside the scope of
   * the actual Container component will have to be moved inside of it or of a
   * hook, and placed within the i18n wrapper in order to implement
   * translations.
   *
   * We can also 'seed' the sidebar and navigation with a link to the default
   * app, whatever Product decides it to be. At the moment, it's empty. The "/"
   * or "Home" link can be accessed by clicking the Granular Logo.
   */
  const APPS_UPPER: Record<number | string, FlexibleNavItem[]> = {
    0: [
      {
        href: "#",
        icon: GKIconName.KeyboardArrowLeft,
        label: intl.formatMessage({ id: "back_to_classic" }),
        shortLabel: "Insights", // TODO: Ask if this needs to be translated.
        tooltip: intl.formatMessage({
          id: "Navigate_to_features_in_the_older_version_of_Granular_Insights",
        }),
        className: "back-to-classic",
        onClick: () => goToClassicInsights(insightsUrl),
      },
    ],
  };

  /**
   * Insert any apps that specify a `null` grouping. Sorting _was_ done
   * internally within the temporary "NO_GROUPING" group.
   */
  if (topNavItems?.length) {
    APPS_UPPER[1] = topNavItems;
  }

  const navigateToHelp = () => {
    const internalSupportEmailDomains = [
      "corteva.com",
      "plantpioneer.com",
      "pioneer.com",
    ];
    const emailDomain = user?.data?.email
      ? user.data.email.split("@")[1]!.toLowerCase()
      : "";
    const internalSupportContentUser = Boolean(
      internalSupportEmailDomains.includes(emailDomain),
    );
    const internalUrl = internalSupportContentUser ? "/signin" : "";
    window.open(
      `https://support.insights.granular.ag/hc/${locale.toLocaleLowerCase()}${internalUrl}`,
    );
  };

  /**
   * These are towards the bottom of the sidebar. Stuff like settings, help,
   * etc.
   *
   * NOTE: The value of the key does not matter in this case. Pick your
   * favorite number 🎲
   */
  const APPS_LOWER: Record<number, FlexibleNavItem[]> = {
    1000: APPS_LOWER_SIMPLE.map((_) => ({
      to: _.href ?? _.route ?? "#",
      icon: GKIconName[_.icon as keyof typeof GKIconName],
      label: intl.formatMessage({ id: _.label }),
      shortLabel: intl.formatMessage({ id: _.shortLabel }),
      onClick: _.label === "common_help" ? navigateToHelp : undefined,
    })),
  };

  // The Mobile app uses this to turn off the navigation bar entirely through
  // a query param. They wanted to handle navigation with a native component.
  const [disableNav] = useAtom(disableNavState);

  // All done! Now we just mostly draw things 🎨

  /* ——————————————————————— Render Container ——————————————————————————— */

  return (
    <>
      {/* TODO: What does this do? Where's documentation about this? */}
      {<RedirectToLandingPageHookContainer navApps={appRuntimeInfo} />}

      <Helmet>
        <title>Granular Insights</title>
        <style type="text/css">{`.gk-main-nav {display: ${
          disableNav ? "none" : "inherit"
        }}`}</style>
      </Helmet>
      <GKLayoutShell
        alertsOverlay
        home={{ to: "/" }}
        primaryNavItems={
          disableNav ? undefined : { ...APPS_UPPER, ...groupedNavItems }
        }
        secondaryNavItems={disableNav ? undefined : APPS_LOWER}
      >
        <ImpersonationContext />
        <AlertCleaner />
        <Suspense fallback={<Loading />}>
          <Routes>
            {/*
              Use the list we generated at Container mount to map each
              App to its route, which is simply its folder name in apps/

              ⚠️ Apps with multiple routes should have a single "namespace" route here

              That's it.

              DO NOT OVERTHINK THIS.
              IT SHOULD BE THIS SIMPLE.
              THIS SHOULD NOT REQUIRE EXCEPTIONS.
              */}
            {Object.values(appRuntimeInfo)
              .flat()
              .map(({ namespace, component, route, routable }) => {
                const App = component;

                return (
                  <Route
                    key={`fabric3-app-${namespace}-${route ?? "index"}`}
                    /**
                     * Ok so the top two props are easy-peasy. This one has a
                     * small nuance. We add a `/*` to the `path` prop below
                     * because this is the ONLY way in which you can tell
                     * React Router to match 🌟nested routes🌟 that come from
                     * your app.
                     *
                     * If you did not do this, you'd have to specify the full
                     * relative path to a component/view in your app:
                     *
                     *    /my-app/foo
                     *
                     *    /my-app/foo/bar/:id
                     *
                     * Instead of just setting up your router in `App.tsx`
                     * like this:
                     *
                     *    foo
                     *
                     *    foo/bar/:id
                     *
                     * One fewer thing for you to think about 🌸🥰 And this
                     * means that you can rename your app's namespace from
                     * `my-app` to `my-other-app` and things will just work.
                     * That's not really as much of an advantage as you
                     * writing less and worrying less about specifying the
                     * full relative path ✨
                     */
                    path={`${namespace}/*`}
                    element={isLocalhost || routable ? <App /> : <ComingSoon />}
                  />
                );
              })}

            <Route path="/" element={<Hello />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Chat />
        </Suspense>
      </GKLayoutShell>
    </>
  );
};

/**
 * We finally export the Container after wrapping it in an I18n context. See
 * the `Shell` component in the Core for why we do this here and not in the
 * Core.
 */
const PolyglotContainer = withIntlProvider(Container, {
  translations,
});

export default PolyglotContainer;
